import AccountIndex from '../../views/account';
import CompanyIndex from '../../views/company';
import CompanyRegistrationIndex from '../../views/company-registration';
import CompanyAccountSettingsIndex from '../../views/company-account-settings';
import DatabaseIndex from '../../views/database';
import DeviceIndex from '../../views/device';
import HomeIndex from '../../views/home';
import LightIndex from '../../views/light';
import OverviewIndex from '../../views/overview';
import ProgramIndex from '../../views/program';
import ProjectIndex from '../../views/project';
import ProjectReportIndex from '../../views/project-report';
import ProjectEdit from '../../views/project/edit';
import SubprojectList from '../../views/project/SubprojectList';
import ReportIndex from '../../views/report';
import SettingsIndex from '../../views/settings';
import SubstationIndex from '../../views/substation';
import UserIndex from '../../views/user';
import CompanyTierChangeIndex from '../../views/company-tier-change';
import NotificationSettingsIndex from '../../views/notification-settings';
import AccessTokenIndex from '../../views/accessToken';

export const mainRoutes = [
    {
        path: '/',
        exact: true,
        component: HomeIndex,
        role: ['ROLE_SUPERADMIN', 'ROLE_COMPANY_ADMIN', 'ROLE_USER'],
    },
    {
        path: '/project',
        exact: true,
        component: ProjectIndex,
        role: ['ROLE_SUPERADMIN', 'ROLE_COMPANY_ADMIN', 'ROLE_USER'],
        tier: ['LITE', 'COMPACT', 'PRO'],
    },
    {
        path: '/user',
        exact: true,
        component: UserIndex,
        role: ['ROLE_SUPERADMIN', 'ROLE_COMPANY_ADMIN'],
        tier: ['PRO'],
    },
    {
        path: '/admin/report',
        exact: true,
        component: ReportIndex,
        role: ['ROLE_SUPERADMIN'],
    },
    {
        path: '/admin/database',
        exact: true,
        component: DatabaseIndex,
        role: ['ROLE_SUPERADMIN'],
    },
    {
        path: '/project/subprojects',
        exact: true,
        component: SubprojectList,
        role: ['ROLE_SUPERADMIN', 'ROLE_COMPANY_ADMIN', 'ROLE_USER'],
        tier: ['PRO'],
    },
    {
        path: '/project/overview',
        exact: true,
        component: OverviewIndex,
        role: ['ROLE_SUPERADMIN', 'ROLE_COMPANY_ADMIN', 'ROLE_USER'],
        tier: ['LITE', 'COMPACT', 'PRO'],
    },
    {
        path: '/project/cabinets',
        exact: true,
        component: SubstationIndex,
        role: ['ROLE_SUPERADMIN', 'ROLE_COMPANY_ADMIN', 'ROLE_USER'],
        tier: ['LITE', 'COMPACT', 'PRO'],
    },
    {
        path: '/project/devices',
        exact: true,
        component: DeviceIndex,
        role: ['ROLE_SUPERADMIN', 'ROLE_COMPANY_ADMIN', 'ROLE_USER'],
        tier: ['LITE', 'COMPACT', 'PRO'],
    },
    {
        path: '/project/programs',
        exact: true,
        component: ProgramIndex,
        role: ['ROLE_SUPERADMIN', 'ROLE_COMPANY_ADMIN', 'ROLE_USER'],
        tier: ['PRO'],
    },
    {
        path: '/project/lights',
        exact: true,
        component: LightIndex,
        role: ['ROLE_SUPERADMIN', 'ROLE_COMPANY_ADMIN', 'ROLE_USER'],
        tier: ['PRO'],
    },
    {
        path: '/project/reports',
        exact: true,
        component: ProjectReportIndex,
        role: ['ROLE_SUPERADMIN', 'ROLE_COMPANY_ADMIN', 'ROLE_USER'],
        tier: ['LITE', 'COMPACT', 'PRO'],
    },
    {
        path: '/project/accounts',
        exact: true,
        component: AccountIndex,
        role: ['ROLE_SUPERADMIN', 'ROLE_COMPANY_ADMIN', 'ROLE_USER'],
        tier: ['PRO'],
    },
    {
        path: '/project/notifications',
        exact: true,
        component: NotificationSettingsIndex,
        role: ['ROLE_SUPERADMIN', 'ROLE_COMPANY_ADMIN', 'ROLE_USER'],
        tier: ['LITE', 'COMPACT', 'PRO'],
    },
    {
        path: '/project/settings',
        exact: true,
        component: ProjectEdit,
        role: ['ROLE_SUPERADMIN', 'ROLE_COMPANY_ADMIN'],
        tier: ['LITE', 'COMPACT', 'PRO'],
    },
    {
        path: '/company',
        exact: true,
        component: CompanyIndex,
        role: ['ROLE_SUPERADMIN', 'ROLE_ACCOUNT_ADMIN'],
    },
    {
        path: '/companyRegistration',
        exact: true,
        component: CompanyRegistrationIndex,
        role: ['ROLE_ACCOUNT_ADMIN'],
    },
    {
        path: '/companyTierChange',
        exact: true,
        component: CompanyTierChangeIndex,
        role: ['ROLE_FINANCE_ADMIN'],
    },
    {
        path: '/settings',
        exact: true,
        component: SettingsIndex,
        role: ['ROLE_SUPERADMIN'],
    },
    {
        path: '/companyAccountSettings',
        exact: true,
        component: CompanyAccountSettingsIndex,
        role: ['ROLE_COMPANY_ACCOUNT_HOLDER'],
    },
    {
        path: '/accessToken',
        exact: true,
        component: AccessTokenIndex,
        role: ['ROLE_ACCESS_USER'],
    },
];
