import { LockOutlined } from '@ant-design/icons';
import { geti18nText, NyDataEdit, NyRequestResolver, NySearchField, NySession, RESPONSE } from '@nybble/nyreact';
import { Col, Form, Input, notification, Row } from 'antd';
import React, { useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { getSearchFormat, setSearchFormat } from '../../utils/Utils';

const UserEdit = (props: any) => {
    const translate_map_option_text = 'users.role.';
    const [editHeader, setEditHeader] = useState(geti18nText('users.edit.new'));
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState(undefined);
    const [user, setUser] = useState(null);
    const [company, setCompany] = useState(null);
    const [isCreate, setIsCreate] = useState(false);
    const [active, setActive] = useState(false);

    const [form] = Form.useForm();

    const validatePassword = (rule: any, value: any, callback: any) => {
        if (value === undefined || value.length < 6) {
            callback(geti18nText('register.validation.password.short'));
        } else {
            callback();
        }
    };

    function setValues(user: any) {
        setEditHeader(geti18nText('users.edit.username') + ': ' + user.username + ' (' + user.id + ')');
        setUser(user.id);
        setUsername(user.username);
        setActive(user.active);
        setCompany(
            user.company != null ? (user.company.constructor === String ? user.company : user.company.id) : undefined
        );

        if (user.company) {
            user.company = setSearchFormat(user.company);
        }

        if (Array.isArray(user.roles)) {
            user.roles = user.roles.map((item: any) => {
                let ret: any = {};
                ret.id = item.id + '';
                ret.key = item.id + '';
                ret.label = item.authority;
                return ret;
            });
        }

        delete user.active;
        delete user.enabled;
        delete user.accountExpired;
        delete user.accountLocked;
        delete user.passwordExpired;
        form.setFieldsValue(user);
        NyRequestResolver.requestGet(CONSTANTS_REQ.USER_ROLE.FOR_USER_ID + '/' + user.id, {}).then((result) => {
            if (result.status === RESPONSE.OK && result.data instanceof Array && result.data.length > 0) {
                form.setFieldsValue({
                    userRoleId: {
                        id: result.data[0].id,
                        authority: geti18nText(translate_map_option_text + result.data[0].authority.toLowerCase()),
                    },
                });
            }
        });
    }

    const onModalClose = () => {
        form.resetFields();
        setUsername(undefined);
        setEditHeader(geti18nText('users.edit.new'));
    };

    function canCreate() {
        return NySession.hasAnyRole(['ROLE_SUPERADMIN', 'ROLE_COMPANY_ADMIN']);
    }

    function okNotification() {
        notification.success({
            message: geti18nText('app.default.save.ok'),
            description: geti18nText('app.default.save.ok.desc'),
            duration: 3,
        });
    }

    const deleteUser = () => {
        setLoading(true);
        let fileId = form.getFieldsValue(['id']);
        NyRequestResolver.requestDelete(CONSTANTS_REQ.USER.DELETE + fileId['id']).then((result: any) => {
            setLoading(false);
            if (result && result.status === RESPONSE.OK) {
                okNotification();
                props.setVisible(false);
                if (props.onSave) {
                    props.onSave();
                }
                form.resetFields();
            } else {
                var msg = '';
                if (result.data) {
                    if (result.data.error) {
                        msg = JSON.stringify(result.data.error);
                        msg = msg.replace('null', '');
                        if (result.data.error.toString().startsWith('constraint_violation_exception')) {
                            msg = msg
                                .replace(
                                    'constraint_violation_exception',
                                    geti18nText('lights.delete.constraint.violation.exception')
                                )
                                .replace(':', '');
                        }
                    } else if (result.data.message) {
                        if (result.data.message.toString().includes('violates foreign key constraint')) {
                            msg = geti18nText('lights.delete.constraint.violation.exception');
                        } else {
                            msg = result.data.message;
                        }
                    }
                }
                if (msg.length > 0) {
                    // error(msg);
                }
            }
        });
    };

    const normalizeValues = (values: any) => {
        let normalized = {
            ...values,
            roles: Array.isArray(form.getFieldValue('roles'))
                ? form.getFieldValue('roles').map((item: any) => {
                      let ret: any = {};
                      if (item.hasOwnProperty('value')) {
                          ret.id = parseInt(item.value, 10);
                      } else if (item.hasOwnProperty('id')) {
                          ret.id = parseInt(item.id, 10);
                      }
                      return ret;
                  })
                : null,
        };

        if (normalized.company) {
            normalized.company = getSearchFormat(normalized.company);
        }

        return normalized;
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            setIsCreate={setIsCreate}
            url={CONSTANTS_REQ.ADMIN.USERS_EDIT}
            customButtons={
                isCreate || active
                    ? []
                    : [
                          {
                              text: geti18nText('app.default.button.delete'),
                              loading: props.loading,
                              style: { backgroundColor: '#c70000', color: 'white' },
                              className: '',
                              onClick: deleteUser,
                              addPopconfirm: true,
                              popConfirmMsg: geti18nText('app.default.destructive.confirm'),
                              popConfirmOk: geti18nText('app.default.button.delete'),
                              popConfirmCancel: geti18nText('app.default.button.cancel'),
                          },
                      ]
            }
            setValues={setValues}
            onModalClose={onModalClose}
            hideActivationButtons={!canCreate}
            width={900}
            form={form}
            normalize={normalizeValues}
            {...props}
        >
            <React.Fragment>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            label={
                                geti18nText('users.edit.username') +
                                ' (' +
                                geti18nText('users.table.column.username') +
                                ')'
                            }
                            name="username"
                            rules={
                                username !== 'admin'
                                    ? [
                                          { type: 'email', message: geti18nText('users.validation.username.email') },
                                          { required: true, message: geti18nText('users.validation.username') },
                                      ]
                                    : []
                            }
                        >
                            <Input disabled={!isCreate} />
                        </Form.Item>
                        <Form.Item
                            style={!isCreate ? { display: 'none' } : {}}
                            label={geti18nText('register.form.password')}
                            hasFeedback
                            name="password"
                            rules={[
                                { required: true, message: geti18nText('register.validation.password') },
                                {
                                    validator: validatePassword,
                                },
                            ]}
                        >
                            <Input.Password
                                disabled={loading}
                                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                            />
                        </Form.Item>

                        <Form.Item
                            style={isCreate ? { display: 'none' } : {}}
                            label={geti18nText('users.edit.password_change')}
                            name="newPassword"
                        >
                            <Input.Password />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label={geti18nText('users.edit.first_name')} name="firstName">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('users.edit.last_name')} name="lastName">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        {NySession.hasAnyRole(['ROLE_SUPERADMIN']) ? (
                            <Form.Item
                                label={geti18nText('users.edit.role')}
                                name="roles"
                                rules={[{ required: true, message: geti18nText('users.validation.role') }]}
                            >
                                <NySearchField
                                    mode="multiple"
                                    url={CONSTANTS_REQ.USER_ROLE.USER_ROLES}
                                    map={{ id: 'id', label: 'text' }}
                                    searchBy="authority"
                                />
                            </Form.Item>
                        ) : (
                            <Form.Item hidden={true} name="roles">
                                <Input hidden={true} />
                            </Form.Item>
                        )}
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        {NySession.hasAnyRole(['ROLE_SUPERADMIN']) ? (
                            <Form.Item
                                label={geti18nText('users.edit.company')}
                                name="company"
                                rules={
                                    username !== 'admin'
                                        ? [{ required: true, message: geti18nText('users.validation.company') }]
                                        : []
                                }
                            >
                                <NySearchField
                                    url={CONSTANTS_REQ.COMPANY.SEARCH}
                                    map={{ id: 'id', label: 'text' }}
                                    searchBy="name"
                                />
                            </Form.Item>
                        ) : (
                            <Form.Item hidden={true} name="company">
                                <Input hidden={true} />
                            </Form.Item>
                        )}
                    </Col>
                </Row>
            </React.Fragment>
        </NyDataEdit>
    );
};

export default UserEdit;
