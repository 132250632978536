import { PoweroffOutlined } from '@ant-design/icons';
import { getColumnSearch, geti18nText, NyDataTable, NyRequestResolver, NySession } from '@nybble/nyreact';
import { Button } from 'antd';
import React, { useRef, useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import moment from 'moment';
import DeviceEdit from './edit';

const DevicePowerControl = (props: any) => {
    const [editModalVisible, setEditModalVisible] = useState<any>(false);
    const [editModalData, setEditModalData] = useState<any>();
    const [refreshTable, setRefreshTable] = useState<any>(0);

    const setDefaultFilterValue = () => {
        let filter = [{ field: 'active', condition: 'equals_bool', value: 1 }];
        if (props.substationId) {
            filter.push({ field: 'substation.id', condition: 'equals', value: props.substationId });
        }
        return filter;
    };

    const openManagement = (id: Number) => {
        setEditModalData({ id: id });
        setEditModalVisible(true);
    };

    return (
        <>
            <NyDataTable
                url={CONSTANTS_REQ.DEVICE.LIST}
                buttonsClassName={'buttons-sticky'}
                showRecordModal={true}
                modalComponent={DeviceEdit}
                hideButtons={true}
                setDefaultFilterValue={setDefaultFilterValue}
                scroll={{ y: 10000, x: 600 }}
                fetchWhenChange={refreshTable}
                columns={[
                    {
                        title: geti18nText('devices.table.column.name'),
                        width: '20%',
                        dataIndex: 'name',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('devices.table.column.mac'),
                        width: '20%',
                        dataIndex: 'macAddress',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    ...(NySession.hasAnyRole(['ROLE_SUPERADMIN'])
                        ? [
                              {
                                  title: geti18nText('devices.table.column.communication'),
                                  render: (text: any, record: any) => {
                                      if (
                                          record.lastActivity &&
                                          moment().diff(moment(record.lastActivity), 'seconds') <= 86400
                                      ) {
                                          return <PoweroffOutlined style={{ color: 'green' }} />;
                                      } else {
                                          return <PoweroffOutlined style={{ color: 'red' }} />;
                                      }
                                  },
                              },
                          ]
                        : []),
                    {
                        title: geti18nText('devices.status.table.column.cb'),
                        render: (text: any, record: any) => {
                            if (record.deviceStatus && record.deviceStatus.cb) {
                                return <PoweroffOutlined style={{ color: 'green' }} />;
                            } else {
                                return <PoweroffOutlined style={{ color: 'red' }} />;
                            }
                        },
                    },
                    {
                        title: geti18nText('devices.status.table.column.r1'),
                        render: (text: any, record: any) => {
                            if (record.deviceStatus && record.deviceStatus.r1) {
                                return <PoweroffOutlined style={{ color: 'green' }} />;
                            } else {
                                return <PoweroffOutlined style={{ color: 'red' }} />;
                            }
                        },
                    },
                    {
                        title: geti18nText('devices.status.table.column.r2'),
                        render: (text: any, record: any) => {
                            if (record.deviceStatus && record.deviceStatus.r2) {
                                return <PoweroffOutlined style={{ color: 'green' }} />;
                            } else {
                                return <PoweroffOutlined style={{ color: 'red' }} />;
                            }
                        },
                    },
                    ...(NySession.hasAnyRole(['ROLE_SUPERADMIN'])
                        ? [
                              {
                                  title: geti18nText('substations.edit.power'),
                                  render: (text: any, record: any) => {
                                      return (
                                          <div style={{ textAlign: 'center' }}>
                                              <Button
                                                  key="powerOff"
                                                  onClick={() => openManagement(record.id)}
                                                  style={{ marginRight: '10px' }}
                                              >
                                                  {geti18nText('devices.tab.management')}
                                              </Button>
                                          </div>
                                      );
                                  },
                              },
                          ]
                        : []),
                ]}
            />

            {React.createElement(
                DeviceEdit,
                {
                    isModal: true,
                    visible: editModalVisible,
                    setVisible: setEditModalVisible,
                    value: editModalData,
                    onSaveAndGetData: () => {
                        setRefreshTable((prevValue: any) => prevValue + 1);
                        setEditModalVisible(false);
                        setEditModalData(undefined);
                    },
                    onModalClose: () => {
                        setEditModalVisible(false);
                        setEditModalData(undefined);
                    },
                    openManagementTab: true,
                },
                null
            )}
        </>
    );
};

export default DevicePowerControl;
