import { geti18nText, NyLanguageSelector, NyRequestResolver, NySession, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Button, Divider, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { HashRouter, Link, Route, Switch } from 'react-router-dom';
import logoIcon from './assets/images/logo.png';
import './assets/scss/index.scss';
import LayoutIndex from './components/layout';
import Login from './components/login';
import Register from './components/register';
import { APPLICATION_KEY, CONSTANTS_REQ, URL_PREFIX } from './utils/Constants';
import { LoadEnums } from './utils/Enums';

const App = () => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        initApp();
    }, []);

    const initApp = async () => {
        setLoading(true);
        await NySession.init();
        await LoadEnums();
        await LoadAppSettings();
        setLoading(false);
    };

    const LoadAppSettings = async function () {
        await NyRequestResolver.requestGet(CONSTANTS_REQ.SETTINGS.GET_SETTINGS, { group: 'application' }).then(
            (result: any) => {
                if (result.status === RESPONSE.OK) {
                    let groupedSettings: any = {};
                    for (const setting of result.data) {
                        if (!groupedSettings[setting.settingGroup]) {
                            groupedSettings[setting.settingGroup] = {};
                        }
                        groupedSettings[setting.settingGroup][setting.settingKey] = {
                            id: setting.id,
                            value: setting.settingValue,
                        };
                    }
                    sessionStorage.setItem(APPLICATION_KEY + 'settings', JSON.stringify(groupedSettings));
                }
            }
        );
    };

    const LoginComponent = () => (
        <Login
            logo={
                <Divider orientation="center">
                    <img src={logoIcon} alt={geti18nText('app.title')} style={{ maxWidth: '290px' }} />
                </Divider>
            }
            loginErrorText={geti18nText('app.default.login.error')}
            validationUsernameText={geti18nText('app.default.login.validation.username')}
            validationPasswordText={geti18nText('app.default.login.validation.password')}
            usernamePlaceholderText={geti18nText('app.default.login.username.placeholder')}
            passwordePlaceholderText={geti18nText('app.default.login.password.placeholder')}
            loginButtonText={geti18nText('app.default.login.button')}
            languageSelector={
                NySession.getAppValue('I18N') != null &&
                NySession.getAppValue('I18N').language && (
                    <NyLanguageSelector
                        style={{ marginBottom: '10px' }}
                        languages={NySession.getAppValue('I18N').language.available}
                        selectedLanguage={NyUtils.getSelectedLanguage()}
                        onSelect={(lang: object) => {
                            NyUtils.saveDefaults(lang);
                        }}
                        reloadPage={true}
                    />
                )
            }
            customButtons={
                <React.Fragment>
                    <Link to={'/register'}>
                        <Button style={{ marginBottom: '10px' }} block>
                            {geti18nText('companyRegistration.button')}
                        </Button>
                    </Link>
                </React.Fragment>
            }
            redirectTo="/"
            url={URL_PREFIX + 'api/login'}
        />
    );

    const RegisterComponent = () => (
        <Register
            logo={
                <Divider orientation="center">
                    <img src={logoIcon} alt={geti18nText('app.title')} style={{ maxWidth: '290px' }} />
                </Divider>
            }
            languageSelector={
                NySession.getAppValue('I18N') != null &&
                NySession.getAppValue('I18N').language && (
                    <NyLanguageSelector
                        style={{ marginBottom: '10px' }}
                        languages={NySession.getAppValue('I18N').language.available}
                        selectedLanguage={NyUtils.getSelectedLanguage()}
                        onSelect={(lang: object) => {
                            NyUtils.saveDefaults(lang);
                        }}
                        reloadPage={true}
                    />
                )
            }
        />
    );

    if (loading) {
        return (
            <div className="spin-container">
                <Spin size="large" />
            </div>
        );
    } else {
        return (
            <HashRouter>
                <Switch>
                    <Route exact path="/login" component={() => <LoginComponent />} />
                    <Route exact path="/register" component={() => <RegisterComponent />} />
                    <Route path="/" component={LayoutIndex} />
                </Switch>
            </HashRouter>
        );
    }
};

export default App;
