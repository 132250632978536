import React, { useState, useRef, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Divider, Typography } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Redirect, useHistory } from 'react-router-dom';
import INyLogin from './types';
import { geti18nText, NyProgressNotificationTimer, NySession, NyUtils } from '@nybble/nyreact';
import SSE from '../layout/see';

const Login = ({
    title = geti18nText('app.default.login.title'),
    redirectTo = '/',
    url = '',
    loginErrorText = geti18nText('app.default.login.error'),
    validationUsernameText = geti18nText('app.default.login.validation.username'),
    validationPasswordText = geti18nText('app.default.login.validation.password'),
    usernamePlaceholderText = geti18nText('app.default.login.username.placeholder'),
    passwordePlaceholderText = geti18nText('app.default.login.password.placeholder'),
    loginButtonText = geti18nText('app.default.login.button'),
    languageSelector,
    logo,
    customFields,
    customButtons,
    customLoginFunction,
    classNameBody,
    classNameFormDiv,
    classNameInput,
    classNameButton,
    login2FA = false,
    url2FA = '',
}: INyLogin) => {
    const [validateStatus, setValidateStatus] = useState<any>('');
    const usernameInput = useRef<Input>(null);
    const [errorText, setErrorText] = useState<any>(loginErrorText);
    const [start, setStart] = useState(false);
    const [showTimeNotification, setShowTimeNotification] = useState(false);
    const intervalRef = useRef<any>(null);
    const eventSourceRef = useRef<any>(null);

    useEffect(() => {
        if (usernameInput != null && usernameInput.current != null) {
            usernameInput.current.focus();
        }
    }, []);

    const login = (values: any) => {
        if (customLoginFunction) {
            customLoginFunction(values);
        } else {
            setValidateStatus('validating');
            NySession.loginUser(values.username, values.password, url != '' ? url : '').then((isOk) => {
                if (isOk) {
                    setValidateStatus('success');
                } else {
                    setValidateStatus('error');
                    if (usernameInput != null && usernameInput.current != null) {
                        usernameInput.current.focus();
                    }
                }
            });
        }
    };

    async function login2Fa(values: any) {
        setValidateStatus('validating');
        let session = NyUtils.loadSession();
        const body = { username: values.username, password: values.password };
        eventSourceRef.current = new SSE(url2FA, {
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            payload: JSON.stringify({ body: JSON.stringify(body) }),
        });

        eventSourceRef.current.onmessage = (e: any) => {
            let notificationResult = JSON.parse(e.data);
            console.log('Login msg: ', notificationResult);
            switch (notificationResult.status) {
                case 'ERROR':
                    console.log('Error login');
                    setValidateStatus('error');
                    clearInterval(intervalRef.current);
                    eventSourceRef.current.close();
                    break;

                case 'LOGIN_FA':
                    const userFA = notificationResult.userFA;
                    if (userFA != null && userFA.active2fa === true) {
                        setShowTimeNotification(true);
                        setStart(true);
                    } else {
                        eventSourceRef.current.close();
                        session.user = notificationResult.user;
                        if (session.user.expires_in != undefined) {
                            session.user.expires_at = Date.now() + (session.user.expires_in - 5) * 1000;
                        }
                        NyUtils.saveSession(session);
                        setValidateStatus('success');
                    }
                    break;
                case 'USER_RESPONSE':
                    clearInterval(intervalRef.current);
                    setShowTimeNotification(false);
                    eventSourceRef.current.close();
                    if (notificationResult.confirmed === true) {
                        session.user = notificationResult.user;
                        if (session.user.expires_in != undefined) {
                            session.user.expires_at = Date.now() + (session.user.expires_in - 5) * 1000;
                        }
                        NyUtils.saveSession(session);
                        setValidateStatus('success');
                    } else {
                        setValidateStatus('error');
                        setErrorText(geti18nText('app.default.time.authorisation.cancel'));
                    }
                    break;
            }
        };

        eventSourceRef.current.onerror = () => {
            console.log('Error login');
            setValidateStatus('error');
            clearInterval(intervalRef.current);
            eventSourceRef.current.close();
        };

        eventSourceRef.current.stream();
    }

    function onTimerFinish() {
        setShowTimeNotification(false);
        setValidateStatus('error');
        clearInterval(intervalRef.current);
        eventSourceRef.current.close();
        setStart(false);
        setErrorText(geti18nText('app.default.time.authorisation.up'));
    }

    function onStart(e: any) {
        intervalRef.current = e;
    }

    const ShowError = ({ show, loginErrorText = '' }: { show: boolean; loginErrorText?: string }) => {
        const { Text } = Typography;

        if (show) {
            return (
                <Row gutter={[16, 8]}>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Text type="danger">{loginErrorText}</Text>
                    </Col>
                </Row>
            );
        }
        return null;
    };

    if (NySession.isUserAuthenticated()) {
        return <Redirect to={redirectTo} />;
    } else {
        return (
            <Row justify="center" align="middle" style={{ height: '100vh' }} className={classNameBody}>
                <Col xs={{ span: 24 }} sm={{ span: 20 }} md={{ span: 12 }} lg={{ span: 8 }} xxl={{ span: 4 }}>
                    <div className={classNameFormDiv ? classNameFormDiv : 'ant-notification-notice'}>
                        <Form
                            onFinish={login2FA === true ? login2Fa : login}
                            className="login-form animated fadeIn faster"
                        >
                            {logo != undefined && logo}
                            {logo === undefined && <Divider orientation="center">{title}</Divider>}

                            {customFields != undefined && customFields}
                            {customFields === undefined && (
                                <>
                                    <Form.Item
                                        name="username"
                                        hasFeedback
                                        validateStatus={validateStatus}
                                        rules={[
                                            {
                                                required: true,
                                                message: validationUsernameText,
                                            },
                                        ]}
                                    >
                                        <Input
                                            className={classNameInput}
                                            disabled={validateStatus === 'validating'}
                                            ref={usernameInput}
                                            prefix={<UserOutlined />}
                                            placeholder={usernamePlaceholderText}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="password"
                                        hasFeedback
                                        validateStatus={validateStatus}
                                        rules={[
                                            {
                                                required: true,
                                                message: validationPasswordText,
                                            },
                                        ]}
                                    >
                                        <Input
                                            className={classNameInput}
                                            disabled={validateStatus === 'validating'}
                                            prefix={<LockOutlined type="lock" />}
                                            type="password"
                                            placeholder={passwordePlaceholderText}
                                        />
                                    </Form.Item>
                                </>
                            )}

                            {languageSelector != undefined && languageSelector}
                            <ShowError show={validateStatus === 'error'} loginErrorText={errorText} />
                            {customFields === undefined && (
                                <Form.Item>
                                    <Button
                                        className={classNameButton}
                                        type="primary"
                                        htmlType="submit"
                                        style={{ width: classNameButton ? undefined : '100%' }}
                                        disabled={validateStatus === 'validating'}
                                        loading={validateStatus === 'validating'}
                                    >
                                        {loginButtonText}
                                    </Button>
                                </Form.Item>
                            )}

                            {customButtons != undefined && customButtons}
                        </Form>
                        {login2FA === true && showTimeNotification === true && (
                            <NyProgressNotificationTimer start={start} onStart={onStart} onFinish={onTimerFinish} />
                        )}
                    </div>
                </Col>
            </Row>
        );
    }
};

export default Login;
