import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { geti18nText, NyLanguageSelector, NySession, NyUtils } from '@nybble/nyreact';
import { Button, Dropdown, Layout, Menu, PageHeader, Result, Tag } from 'antd';
import React, { useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import { APPLICATION_KEY } from '../../utils/Constants';
import { hasAnyCompanyTier } from '../../utils/Utils';
import LayoutMenu from '../layout-menu';
import './index.css';
import { projectMenu } from './menu';
import { mainRoutes } from './routes';

const { Content } = Layout;

const LayoutIndex = () => {
    const history = useHistory();
    const location = useLocation();

    const [menu, setMenu] = useState(projectMenu);

    function generateRoutes(routes: any) {
        let retValue = [];
        let route;
        for (route of routes) {
            if (hasAnyCompanyTier(route.tier))
                if (route.role !== undefined && NySession.hasAnyRole(route.role) && !route.disable) {
                    retValue.push(
                        <Route key={route.path} path={route.path} exact={route.exact} component={route.component} />
                    );
                }
        }
        return retValue;
    }

    const logout = () => {
        NySession.logoutUser();
        sessionStorage.removeItem(APPLICATION_KEY);
        history.push('/login');
    };

    const userDropdown = (
        <Menu>
            <Menu.Item key="0" icon={<LogoutOutlined />}>
                <a onClick={logout}>{geti18nText('navbar.user.logout')}</a>
            </Menu.Item>
        </Menu>
    );

    if (!NySession.isUserAuthenticated()) {
        return <Redirect to="/login" />;
    } else {
        return (
            <Layout>
                <PageHeader
                    title={
                        <div className="menu-logo">
                            <img src={logo} />
                        </div>
                    }
                    // title="SYMBIOT - Elumia"
                    className="ny-header header-background"
                    subTitle={<Tag>{geti18nText('login.build') + ' ' + NySession.getAppValue('VERSION')}</Tag>}
                    extra={[
                        <Dropdown key="1" overlay={userDropdown} trigger={['click']}>
                            <Button>
                                <UserOutlined />
                                <span style={{ marginLeft: '8px' }}>
                                    {NySession.getUser() && NySession.getUser().fullName}
                                </span>
                            </Button>
                        </Dropdown>,
                        NySession.getAppValue('I18N') != null && NySession.getAppValue('I18N').language && (
                            <NyLanguageSelector
                                key="2"
                                style={{ marginLeft: '10px' }}
                                languages={NySession.getAppValue('I18N').language.available}
                                selectedLanguage={NyUtils.getSelectedLanguage()}
                                onSelect={(lang: object) => {
                                    NyUtils.saveDefaults(lang);
                                }}
                                reloadPage={true}
                            />
                        ),
                    ]}
                />
                <Layout className="main-layout">
                    <LayoutMenu
                        menuItems={menu}
                        menuHistory={history}
                        menuLocation={location}
                        menuTheme="light"
                        siderClassName="ny-sider"
                        menuClassName="ny-menu"
                    />

                    <Content className="main-content">
                        <Switch>
                            {generateRoutes(mainRoutes)}
                            <Route
                                path="*"
                                render={() => (
                                    <Result
                                        status="404"
                                        title="404"
                                        subTitle={geti18nText('app.default.404')}
                                        extra={<Button type="primary">{geti18nText('app.default.404.button')}</Button>}
                                    />
                                )}
                            />
                        </Switch>
                    </Content>
                </Layout>
            </Layout>
        );
    }
};

export default LayoutIndex;
