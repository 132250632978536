import {
    geti18nText,
    NyDatePicker,
    NyRequestResolver,
    NySearchField,
    NySession,
    NySpinner,
    RESPONSE,
} from '@nybble/nyreact';
import { Checkbox, Col, Form, Row, Select, Switch } from 'antd';
import moment from 'moment';
import 'moment/locale/hr';
import { useEffect, useState } from 'react';
import { Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import {
    CONSTANTS_REQ,
    DEVICE_HD_SD_TYPE,
    ENUMS_DEVICE_HD_SD_TYPE,
    OBIS_CODE,
    REFRESH_RATE_MS,
} from '../../utils/Constants';
import { downloadFile } from '../../utils/Utils';

export const DeviceDataChart = (props: any) => {
    const { Option } = Select;
    const [data, setData] = useState<any>(null);
    const [dataL1, setDataL1] = useState<any>(null);
    const [dataL2, setDataL2] = useState<any>(null);
    const [dataL3, setDataL3] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);
    const [params, setParams] = useState<any>({});
    const [isAllPhase, setIsAllPhase] = useState<any>(false);
    const [showExpectedPower, setShowExpectedPower] = useState<any>(false);
    const [isHd, setIsHd] = useState<any>(false);
    const [isHdRange, setIsHdRange] = useState<any>(true);
    const [codeValue, setCodeValue] = useState<any>();
    const [minDate, setMinDate] = useState<any>(moment().subtract(1, 'day'));
    const [minTime, setMinTime] = useState<any>(moment().add(1, 'hour').startOf('hour'));
    const [maxDate, setMaxDate] = useState<any>(moment());
    const [maxTime, setMaxTime] = useState<any>(moment().add(1, 'hour').startOf('hour'));
    const [hdMeasurements, setHdMeasurements] = useState<any>([]);
    const [hdMeasurement, setHdMeasurement] = useState<any>(undefined);
    const [refreshGraph, setRefreshGraph] = useState<any>(0);
    const [timeInterval, setTimeInterval] = useState<any>(null);

    useEffect(() => {
        if (props.addedData) {
            onCodeChange(OBIS_CODE.POWER.PLUS.TOTAL);
            onRangeChange(minDate, minTime, maxDate, maxTime);
            onHdSdChange(undefined);
            setIsAllPhase(false);
            setShowExpectedPower(false);
            setIsHd(false);
            setIsHdRange(true);
            getLatestHdMeasurements();

            let i = 0;
            const interval = setInterval(() => {
                setRefreshGraph(i++);
            }, REFRESH_RATE_MS);

            if (props.setRefreshInterval) {
                props.setRefreshInterval(interval);
            }

            return () => clearInterval(interval);
        }
    }, []);

    useEffect(() => {
        if (props.exportGraph) {
            onCSVExport();
        }
    }, [props.exportGraph]);

    useEffect(() => {
        performFetch();
    }, [params, isAllPhase, refreshGraph, timeInterval]);

    function cleanData() {
        setData(null);
        setDataL1(null);
        setDataL2(null);
        setDataL3(null);
    }

    function performFetch() {
        if (params && params.code && (params.timestampFrom || params.timestampHd)) {
            if (isAllPhase) {
                fetch(OBIS_CODE.POWER.PLUS.TOTAL, setData);
                fetch(OBIS_CODE.POWER.PLUS.L1, setDataL1);
                fetch(OBIS_CODE.POWER.PLUS.L2, setDataL2);
                fetch(OBIS_CODE.POWER.PLUS.L3, setDataL3);
            } else {
                fetch(params.code, setData);
            }
        } else {
            cleanData();
        }
    }

    function fetch(code: any, setDataFunc: any) {
        setLoading(true);
        var newParams = { ...params };
        if (code != undefined) {
            newParams.code = code;
        }
        var url = props.power_url;
        if (
            [
                OBIS_CODE.ENERGY.PLUS.TOTAL,
                OBIS_CODE.ENERGY.PLUS.L1,
                OBIS_CODE.ENERGY.PLUS.L2,
                OBIS_CODE.ENERGY.PLUS.L3,
            ].includes(newParams.code)
        ) {
            url = props.energy_url;
        }
        NyRequestResolver.requestGet(url, { ...newParams, ...props.addedData }).then((response: any) => {
            setLoading(false);
            if (response.status === RESPONSE.OK) {
                if (moment(maxDate).diff(minDate) > 86400000) {
                    setDataFunc(
                        interpolate(
                            response.data,
                            newParams.code,
                            0,
                            'DD.MM. HH:mm:ss',
                            timeInterval != null ? timeInterval : 120
                        )
                    );
                } else {
                    setDataFunc(
                        interpolate(
                            response.data,
                            newParams.code,
                            0,
                            'HH:mm:ss',
                            timeInterval != null ? timeInterval : 31
                        )
                    );
                }
            } else {
                setDataFunc([]);
            }
        });
    }

    function onCSVExport() {
        setLoading(true);
        NyRequestResolver.requestGet(
            CONSTANTS_REQ.DEVICE_DATA.EXPORT_HD_SD,
            { ...params, ...props.addedData },
            false,
            true
        ).then((result: any) => {
            setLoading(false);
            if (
                result.status === RESPONSE.OK &&
                downloadFile(result, moment().format('YYYYMMDDHHmmss') + '_' + props.addedData.deviceId + '.csv')
            ) {
            } else {
                console.log(JSON.stringify(result.data.error));
            }
        });
    }

    function onCodeChange(value: any) {
        setCodeValue(value);
        setParams((prevState: any) => {
            return { ...prevState, code: value };
        });
    }

    function onRangeChange(minDate: any, minTime: any, maxDate: any, maxTime: any) {
        if (minDate && minTime) {
            let timestampFrom = minDate.format('YYYY-MM-DD') + ' ' + minTime.format('HH:mm');
            let timestampTo =
                (maxDate ? maxDate : moment()).format('YYYY-MM-DD') +
                ' ' +
                (maxTime ? maxTime : moment()).format('HH:mm');
            setParams((prevState: any) => {
                return {
                    ...prevState,
                    timestampFrom: moment(timestampFrom).valueOf(),
                    timestampTo: moment(timestampTo).valueOf(),
                    timestampHd: undefined,
                };
            });
        } else {
            cleanData();
        }
    }

    const getLatestHdMeasurements = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.HD_MEASUREMENTS, {
            projectId: NySession.getProjectId(),
            deviceId: props.addedData && props.addedData.deviceId ? props.addedData.deviceId : undefined,
        }).then((result: any) => {
            if (result && result.data) {
                setHdMeasurements(result.data);
            }
        });
    };

    const onMinDateChange = (date: any) => {
        setMinDate(date);
        onRangeChange(date, minTime, maxDate, maxTime);
    };

    const onMinTimeChange = (time: any) => {
        setMinTime(time);
        onRangeChange(minDate, time, maxDate, maxTime);
    };

    const onMaxDateChange = (date: any) => {
        setMaxDate(date);
        onRangeChange(minDate, minTime, date, maxTime);
    };

    const onMaxTimeChange = (time: any) => {
        setMaxTime(time);
        onRangeChange(minDate, minTime, maxDate, time);
    };

    const onHdFilterChange = (checked: any) => {
        setIsHdRange(checked);
        if (checked) {
            onRangeChange(minDate, minTime, maxDate, maxTime);
        } else if (hdMeasurement) {
            setParams((prevState: any) => {
                return {
                    ...prevState,
                    timestampHd: hdMeasurement,
                };
            });
        } else {
            cleanData();
        }
    };

    const onHdMeasurementChange = (item: any) => {
        if (item && item.id > 0) {
            setHdMeasurement(item.id);
            setParams((prevState: any) => {
                return {
                    ...prevState,
                    timestampHd: item.id,
                };
            });
        } else {
            setHdMeasurement(undefined);
            cleanData();
        }
    };

    function onHdSdChange(item: any) {
        if (item && item.id > -1) {
            setIsHd(item.id == DEVICE_HD_SD_TYPE.HD);
            setParams((prevState: any) => {
                return {
                    ...prevState,
                    sdType: item.id,
                    timestampHd: item.id == DEVICE_HD_SD_TYPE.HD && !isHdRange ? hdMeasurement : undefined,
                };
            });
        } else {
            setIsHd(false);
            setParams((prevState: any) => {
                return {
                    ...prevState,
                    sdType: undefined,
                    timestampHd: undefined,
                };
            });
        }
    }

    const CustomTooltip = (data: any) => {
        if (data.active) {
            return (
                <div
                    style={{
                        backgroundColor: 'white',
                        border: '1px solid lightgray',
                        padding: '5px',
                        borderRadius: '0.3em',
                    }}
                >
                    <Row>
                        <span>{data.label}</span>
                    </Row>
                    {data.payload != null &&
                        data.payload.map((entry: any) => (
                            <Row>
                                <span style={{ color: entry.color }}>
                                    {entry.name}: {entry.value} {' W'}
                                </span>
                            </Row>
                        ))}
                </div>
            );
        }
        return null;
    };

    const DataChart = (props: any) => {
        const chartData =
            props.phase == 'l1' ? dataL1 : props.phase == 'l2' ? dataL2 : props.phase == 'l3' ? dataL3 : data;
        return (
            <>
                {isAllPhase && (
                    <Row style={{ marginTop: 10, marginLeft: 20 }}>
                        <b>
                            {geti18nText(
                                'obis.active.power.plus' + (props.phase != undefined ? '.' + props.phase : '')
                            )}
                        </b>
                    </Row>
                )}
                <Row style={{ marginTop: 10 }}>
                    {loading ? (
                        <NySpinner />
                    ) : (
                        <Col span={24}>
                            <ResponsiveContainer height={400} width="95%">
                                <ComposedChart
                                    data={chartData}
                                    margin={{
                                        top: 5,
                                        right: 20,
                                        left: 10,
                                        bottom: 5,
                                    }}
                                >
                                    <Tooltip content={<CustomTooltip />} />
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="converted" padding={{ right: 10 }} />
                                    <YAxis unit=" W" />
                                    {chartData &&
                                        showExpectedPower &&
                                        (isAllPhase || codeValue == OBIS_CODE.POWER.PLUS.TOTAL) && (
                                            <Line
                                                name={geti18nText('project.expectedConsumption')}
                                                type="monotone"
                                                dataKey="expected"
                                                stroke="#ff0000"
                                                dot={false}
                                            />
                                        )}
                                    {chartData && (
                                        <Bar
                                            name={geti18nText('project.currentConsumption')}
                                            type="monotone"
                                            dataKey="value"
                                            fill="#8884d8"
                                        />
                                    )}
                                </ComposedChart>
                            </ResponsiveContainer>
                        </Col>
                    )}
                </Row>
            </>
        );
    };

    return (
        <>
            <Row gutter={10}>
                <Col xs={8} xl={3}>
                    <Form.Item label={geti18nText('devices.graph.filter.allPhase')} name="allPhase">
                        <Switch
                            style={{ width: '100%' }}
                            checkedChildren={geti18nText('devices.graph.filter.allPhase.true')}
                            unCheckedChildren={geti18nText('devices.graph.filter.allPhase.false')}
                            checked={isAllPhase}
                            onChange={(checked: any) => setIsAllPhase(checked)}
                        />
                    </Form.Item>
                </Col>
                <Col xs={8} xl={3} hidden={isAllPhase}>
                    <Form.Item label={geti18nText('devices.graph.filter.code')} name="filterCode">
                        <Select
                            defaultValue={OBIS_CODE.POWER.PLUS.TOTAL}
                            onChange={onCodeChange}
                            style={{ width: '100%' }}
                        >
                            <Option value={OBIS_CODE.POWER.PLUS.TOTAL}>{geti18nText('obis.active.power.plus')}</Option>
                            <Option value={OBIS_CODE.POWER.PLUS.L1}>{geti18nText('obis.active.power.plus.l1')}</Option>
                            <Option value={OBIS_CODE.POWER.PLUS.L2}>{geti18nText('obis.active.power.plus.l2')}</Option>
                            <Option value={OBIS_CODE.POWER.PLUS.L3}>{geti18nText('obis.active.power.plus.l3')}</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={8} xl={2}>
                    <Form.Item label={geti18nText('devices.graph.filter.hdsddata')} name="hdsddata">
                        <NySearchField
                            options={ENUMS_DEVICE_HD_SD_TYPE()}
                            defaultValue={{ id: '-1', label: geti18nText('devices.graph.filter.hdsddata.all') }}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            onChange={onHdSdChange}
                        />
                    </Form.Item>
                </Col>
                {isHd && (
                    <Col xs={12} xl={3}>
                        <Form.Item label={geti18nText('devices.graph.filter.hd')} name="hdFilter">
                            <Switch
                                style={{ width: '100%' }}
                                checkedChildren={geti18nText('devices.graph.filter.hd.range')}
                                unCheckedChildren={geti18nText('devices.graph.filter.hd.measurements')}
                                checked={isHdRange}
                                onChange={(checked: any) => onHdFilterChange(checked)}
                            />
                        </Form.Item>
                    </Col>
                )}
                {isHd && !isHdRange && (
                    <Col xs={12} xl={3}>
                        <Form.Item
                            label={geti18nText('light.status.activity.hd.timestamps')}
                            name="hdMeasurements"
                            required
                        >
                            <NySearchField
                                options={hdMeasurements}
                                map={{ id: 'id', label: 'text' }}
                                searchBy="text"
                                onChange={onHdMeasurementChange}
                            />
                        </Form.Item>
                    </Col>
                )}
                {(!isHd || isHdRange) && (
                    <>
                        <Col xs={6} xl={3}>
                            <Form.Item label={geti18nText('devices.graph.filter.span.dateFrom')} required>
                                <NyDatePicker
                                    disabledDate={(current: any) => {
                                        return moment().add(-10, 'days') >= current;
                                    }}
                                    style={{ width: '100%' }}
                                    onChange={onMinDateChange}
                                    value={minDate}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={5} xl={2}>
                            <Form.Item label={geti18nText('devices.graph.filter.span.timeFrom')} required>
                                <NyDatePicker
                                    mode="time"
                                    format="HH:mm:ss"
                                    style={{ width: '100%' }}
                                    onChange={onMinTimeChange}
                                    value={minTime}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={6} xl={3}>
                            <Form.Item label={geti18nText('devices.graph.filter.span.dateTo')}>
                                <NyDatePicker
                                    disabledDate={(current: any) => {
                                        return moment().add(-10, 'days') >= current;
                                    }}
                                    style={{ width: '100%' }}
                                    value={maxDate}
                                    onChange={onMaxDateChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={5} xl={2}>
                            <Form.Item label={geti18nText('devices.graph.filter.span.timeTo')}>
                                <NyDatePicker
                                    mode="time"
                                    format="HH:mm:ss"
                                    style={{ width: '100%' }}
                                    onChange={onMaxTimeChange}
                                    value={maxTime}
                                />
                            </Form.Item>
                        </Col>
                    </>
                )}
                <Col xs={8} xl={3}>
                    <Form.Item label={geti18nText('devices.graph.interval')} name="timeInterval">
                        <Select
                            defaultValue={null}
                            onChange={(e: any) => {
                                setTimeInterval(e);
                            }}
                            style={{ width: '100%' }}
                        >
                            <Option value={null}>{geti18nText('devices.graph.interval.current.view')}</Option>
                            <Option value={15}>{geti18nText('devices.graph.interval.15min')}</Option>
                            <Option value={30}>{geti18nText('devices.graph.interval.30min')}</Option>
                            <Option value={60}>{geti18nText('devices.graph.interval.1h')}</Option>
                            <Option value={1440}>{geti18nText('devices.graph.interval.1d')}</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            {(isAllPhase || codeValue == OBIS_CODE.POWER.PLUS.TOTAL) && (
                <Col>
                    <span>
                        {geti18nText('devices.graph.filter.expected')}
                        <Checkbox
                            style={{ marginLeft: 10 }}
                            checked={showExpectedPower}
                            onChange={(e: any) => setShowExpectedPower(e.target.checked)}
                        ></Checkbox>
                    </span>
                </Col>
            )}
            <DataChart />
            {isAllPhase && (
                <>
                    <DataChart phase="l1" />
                    <DataChart phase="l2" />
                    <DataChart phase="l3" />
                </>
            )}
        </>
    );
};

const interpolate = (data: any, data_type: any, startFromIndex: any, pattern: string, diffMin: number) => {
    var skipZerosFor = [
        OBIS_CODE.ENERGY.PLUS.TOTAL,
        OBIS_CODE.ENERGY.PLUS.L1,
        OBIS_CODE.ENERGY.PLUS.L2,
        OBIS_CODE.ENERGY.PLUS.L3,
    ];
    const newArray = [];
    let i;
    for (i = startFromIndex ? startFromIndex : 0; i < data.length; i++) {
        if (!skipZerosFor.includes(data_type) && i > 0) {
            newArray.push(...addData(data[i - 1].converted, data[i].converted, pattern, diffMin, data[0].expected));
        }
        newArray.push(data[i]);
    }
    return newArray;
};

const addData = (time1: any, time2: any, pattern: string, diffMin: number, expected: number) => {
    var duration = moment.duration(moment(time2, pattern).diff(moment(time1, pattern)));
    if (duration.asMinutes() > diffMin) {
        const retValue = [];
        let insertZeros = 0;
        let startingPoint = moment(time1, pattern);
        for (insertZeros = duration.asMinutes() / diffMin; insertZeros > 0; insertZeros--) {
            startingPoint = startingPoint.add(diffMin, 'm');
            retValue.push({ value: 0, expected: expected, converted: startingPoint.format(pattern) });
        }
        return retValue;
    }
    return [];
};

export { interpolate };
