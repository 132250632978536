import { ExclamationCircleOutlined, PoweroffOutlined } from '@ant-design/icons';
import { geti18nText, NyRequestResolver, NySession, RESPONSE } from '@nybble/nyreact';
import { Button, Card, Col, Modal, Row, Spin } from 'antd';
import Countdown from 'antd/lib/statistic/Countdown';
import { useEffect, useRef, useState } from 'react';
import SSE from '../../components/layout/see';
import Statistic from '../../components/statistic';
import { CONSTANTS_REQ, PROGRAM_OUTPUT } from '../../utils/Constants';

const DeviceManagement = (props: any) => {
    const { deviceId } = props;
    const [deviceStatus, setDeviceStatus] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState<any>(undefined);

    const eventSourceRef = useRef<any>(null);

    useEffect(() => {
        getLatestDeviceStatus();
    }, []);

    function waitForStatusUpdateOrTimeout() {
        eventSourceRef.current = new SSE(CONSTANTS_REQ.DEVICE.LISTEN_DEVICE_STATUS + '?id=' + deviceId, {
            headers: {
                Authorization: 'Bearer ' + NySession.getUserToken(),
            },
        });

        eventSourceRef.current.onmessage = (e: any) => {
            if (e && e.data == '' + deviceId) {
                eventSourceRef.current.close();
                stopTimer();
            }
        };

        eventSourceRef.current.onerror = (e: any) => {
            console.log('sse error', e);
            eventSourceRef.current.close();
            stopTimer();
        };

        eventSourceRef.current.stream();
        startTimer();
    }

    function getLatestDeviceStatus() {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.LATEST_DEVICE_STATUS, {
            deviceId: deviceId,
        }).then((result: any) => {
            setLoading(false);
            if (result.status === RESPONSE.OK) {
                setDeviceStatus(result.data);
            }
        });
    }

    function powerControl(relay: number, state: boolean) {
        setLoading(true);
        NyRequestResolver.requestPost(CONSTANTS_REQ.DEVICE.SEND_POWER_CONTROL_REQUEST, undefined, {
            id: deviceId,
            relay: relay,
            state: state,
        }).then((result: any) => {
            setLoading(false);
            if (result.status === RESPONSE.OK) {
                waitForStatusUpdateOrTimeout();
            }
        });
    }

    function status() {
        setLoading(true);
        NyRequestResolver.requestPost(CONSTANTS_REQ.DEVICE.SEND_STATUS_REQUEST, undefined, {
            id: deviceId,
        }).then((result: any) => {
            setLoading(false);
            if (result.status === RESPONSE.OK) {
                waitForStatusUpdateOrTimeout();
            }
        });
    }

    function additionalData() {
        setLoading(true);
        NyRequestResolver.requestPost(CONSTANTS_REQ.DEVICE.SEND_ADDITIONAL_DATA_REQUEST, undefined, {
            id: deviceId,
        }).then((result: any) => {
            setLoading(false);
            if (result.status === RESPONSE.OK) {
                additionalDataconfirm();
            }
        });
    }

    function emergency(state: boolean) {
        setLoading(true);
        NyRequestResolver.requestPost(CONSTANTS_REQ.DEVICE.SEND_EMERGENCY, undefined, {
            ids: [deviceId],
            state: state,
        }).then((result: any) => {
            setLoading(false);
            if (result.status === RESPONSE.OK) {
                waitForStatusUpdateOrTimeout();
            }
        });
    }

    function syncDevice() {
        setLoading(true);
        NyRequestResolver.requestPost(CONSTANTS_REQ.DEVICE.SYNC, undefined, {
            projectId: NySession.getProjectId(),
            deviceIds: [deviceId],
        }).then((result) => {
            setLoading(false);
            if (result.status === RESPONSE.OK) {
            }
        });
    }

    function startTimer() {
        setLoading(true);
        setTimer(Date.now() + 61 * 1000);
    }

    function stopTimer() {
        if (eventSourceRef.current) {
            eventSourceRef.current.close();
            eventSourceRef.current = undefined;
        }
        setTimer(undefined);
        getLatestDeviceStatus();
    }

    const additionalDataconfirm = () => {
        Modal.confirm({
            title: geti18nText('app.default.save.ok'),
            icon: <ExclamationCircleOutlined />,
            content: geti18nText('devices.status.button.additional.data.executed'),
            cancelButtonProps: { style: { display: 'none' } },
        });
    };

    return (
        <>
            <Spin
                spinning={timer != undefined}
                size="large"
                tip={
                    <Countdown
                        title={geti18nText('devices.status.table.waiting.status')}
                        value={timer}
                        onFinish={stopTimer}
                        format="mm:ss"
                    />
                }
            >
                <Row gutter={[12, 12]} style={{ marginTop: '12px' }}>
                    <Col xs={12} xl={4}>
                        <Card bordered={true} style={{ borderRadius: '1em', height: '100%' }}>
                            <Statistic
                                title={geti18nText('devices.status.table.column.lastFetched')}
                                value={deviceStatus.insertedDate}
                                loading={loading}
                            ></Statistic>
                        </Card>
                    </Col>
                    <Col xs={12} xl={4}>
                        <Card bordered={true} style={{ borderRadius: '1em', height: '100%' }}>
                            <Statistic
                                title={geti18nText('devices.status.table.column.cb')}
                                value={
                                    deviceStatus.cb ? (
                                        <PoweroffOutlined style={{ color: 'green' }} />
                                    ) : (
                                        <PoweroffOutlined style={{ color: 'red' }} />
                                    )
                                }
                                loading={loading}
                            ></Statistic>
                        </Card>
                    </Col>
                    <Col xs={12} xl={4}>
                        <Card bordered={true} style={{ borderRadius: '1em', height: '100%' }}>
                            <Statistic
                                title={geti18nText('devices.status.table.column.r1')}
                                value={
                                    deviceStatus.r1 ? (
                                        <PoweroffOutlined style={{ color: 'green' }} />
                                    ) : (
                                        <PoweroffOutlined style={{ color: 'red' }} />
                                    )
                                }
                                loading={!loading}
                            ></Statistic>
                        </Card>
                    </Col>
                    <Col xs={12} xl={4}>
                        <Card bordered={true} style={{ borderRadius: '1em', height: '100%' }}>
                            <Statistic
                                title={geti18nText('devices.status.table.column.r2')}
                                value={
                                    deviceStatus.r2 ? (
                                        <PoweroffOutlined style={{ color: 'green' }} />
                                    ) : (
                                        <PoweroffOutlined style={{ color: 'red' }} />
                                    )
                                }
                                loading={loading}
                            ></Statistic>
                        </Card>
                    </Col>
                    <Col xs={12} xl={4}>
                        <Card bordered={true} style={{ borderRadius: '1em', height: '100%' }}>
                            <Statistic
                                title={geti18nText('devices.status.table.column.emergency')}
                                value={
                                    deviceStatus.emergency ? (
                                        <span style={{ color: 'red' }}>{geti18nText('app.default.button.yes')}</span>
                                    ) : (
                                        <span style={{ color: 'green' }}>{geti18nText('app.default.button.no')}</span>
                                    )
                                }
                                loading={loading}
                            ></Statistic>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[12, 12]} style={{ marginTop: '12px' }}>
                    <Col>
                        <Card bordered={true} style={{ borderRadius: '1em', height: '100%' }}>
                            <Statistic
                                title={geti18nText('devices.status.button.group.management')}
                                value={
                                    <Row gutter={12}>
                                        <Col>
                                            <Button
                                                style={{ width: '100%', textAlign: 'center' }}
                                                disabled={loading || deviceStatus.emergency}
                                                onClick={() => powerControl(PROGRAM_OUTPUT.CB, !deviceStatus.cb)}
                                            >
                                                {(deviceStatus.cb
                                                    ? geti18nText('devices.status.button.turn.off')
                                                    : geti18nText('devices.status.button.turn.on')) +
                                                    ' ' +
                                                    geti18nText('enum.programOutput.' + PROGRAM_OUTPUT.CB)}
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                style={{ width: '100%', textAlign: 'center' }}
                                                disabled={loading || deviceStatus.emergency}
                                                onClick={() => powerControl(PROGRAM_OUTPUT.R1, !deviceStatus.r1)}
                                            >
                                                {(deviceStatus.r1
                                                    ? geti18nText('devices.status.button.turn.off')
                                                    : geti18nText('devices.status.button.turn.on')) +
                                                    ' ' +
                                                    geti18nText('enum.programOutput.' + PROGRAM_OUTPUT.R1)}
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                style={{ width: '100%', textAlign: 'center' }}
                                                disabled={loading || deviceStatus.emergency}
                                                onClick={() => powerControl(PROGRAM_OUTPUT.R2, !deviceStatus.r2)}
                                            >
                                                {(deviceStatus.r2
                                                    ? geti18nText('devices.status.button.turn.off')
                                                    : geti18nText('devices.status.button.turn.on')) +
                                                    ' ' +
                                                    geti18nText('enum.programOutput.' + PROGRAM_OUTPUT.R2)}
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                style={{ width: '100%', textAlign: 'center' }}
                                                type="primary"
                                                disabled={loading}
                                                onClick={() => emergency(!deviceStatus.emergency)}
                                                className={
                                                    deviceStatus.emergency ? 'ant-btn-green' : 'ant-btn-dangerous'
                                                }
                                            >
                                                {deviceStatus.emergency
                                                    ? geti18nText('devices.table.emergencyCancel')
                                                    : geti18nText('devices.table.emergencyOff')}
                                            </Button>
                                        </Col>
                                    </Row>
                                }
                                loading={loading}
                            ></Statistic>
                        </Card>
                    </Col>
                    <Col>
                        <Card bordered={true} style={{ borderRadius: '1em', height: '100%' }}>
                            <Statistic
                                title={geti18nText('devices.status.button.group.additional')}
                                value={
                                    <Row gutter={12}>
                                        <Col>
                                            <Button
                                                style={{ width: '100%', textAlign: 'center' }}
                                                disabled={loading}
                                                onClick={() => status()}
                                            >
                                                {geti18nText('devices.status.button.status')}
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                style={{ width: '100%', textAlign: 'center' }}
                                                disabled={loading}
                                                onClick={() => additionalData()}
                                            >
                                                {geti18nText('devices.status.button.additional.data')}
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                style={{ width: '100%', textAlign: 'center' }}
                                                disabled={loading}
                                                onClick={() => syncDevice()}
                                            >
                                                {geti18nText('app.default.button.sync')}
                                            </Button>
                                        </Col>
                                    </Row>
                                }
                                loading={loading}
                            ></Statistic>
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </>
    );
};

export default DeviceManagement;
