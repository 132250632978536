import { CheckOutlined, CloseOutlined, PoweroffOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NySession,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Popconfirm, Spin } from 'antd';
import Countdown from 'antd/lib/statistic/Countdown';
import moment from 'moment';
import React, { ReactText, useEffect, useRef, useState } from 'react';
import SSE from '../../components/layout/see';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { hasAnyProjectRole } from '../../utils/Utils';
import AddToSubstation from './AddToSubstation';
import DeviceSync from './DeviceSync';
import DeviceEdit from './edit';

const DeviceIndex = (props: any) => {
    const [refresh, setRefresh] = useState<any>(0);
    const [refreshTable, setRefreshTable] = useState<any>(0);
    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState<any>(undefined);

    const eventSourceRef = useRef<any>(null);

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'project_id', condition: 'equals', value: NySession.getProjectId() },
        ];
    };

    function canCreate() {
        return NySession.hasAnyRole(['ROLE_SUPERADMIN']) || hasAnyProjectRole(['ROLE_PROJECT_ADMIN']);
    }

    useEffect(() => {
        let i = 0;
        const interval = setInterval(() => {
            setRefresh(i++);
        }, 60000);

        return () => clearInterval(interval);
    }, []);

    function waitForStatusUpdateOrTimeout(id: any) {
        eventSourceRef.current = new SSE(CONSTANTS_REQ.DEVICE.LISTEN_DEVICE_STATUS + '?id=' + id, {
            headers: {
                Authorization: 'Bearer ' + NySession.getUserToken(),
            },
        });

        eventSourceRef.current.onmessage = (e: any) => {
            if (e && e.data == '' + id) {
                eventSourceRef.current.close();
                stopTimer();
            }
        };

        eventSourceRef.current.onerror = (e: any) => {
            console.log('sse error', e);
            eventSourceRef.current.close();
            stopTimer();
        };

        eventSourceRef.current.stream();
        startTimer();
    }

    function emergencyOff(ids: any, state: boolean) {
        setLoading(true);
        NyRequestResolver.requestPost(CONSTANTS_REQ.DEVICE.SEND_EMERGENCY, undefined, {
            ids: ids,
            state: state,
        }).then((result: any) => {
            setLoading(false);
            if (result.status === RESPONSE.OK) {
                // if (ids.length == 1) {
                //     waitForStatusUpdateOrTimeout(ids[0]);
                // }
            }
        });
    }

    const rowSelectionModals = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        return (
            <React.Fragment>
                {React.cloneElement(<AddToSubstation />, {
                    hasSelected,
                    selectedRowKeys,
                    selectedRows,
                    onPopupSave,
                    clearSelectedRowKeys,
                })}
                {React.cloneElement(<DeviceSync />, {
                    hasSelected,
                    selectedRowKeys,
                    selectedRows,
                    onPopupSave,
                    clearSelectedRowKeys,
                })}
                <Popconfirm
                    placement="topLeft"
                    title={geti18nText('app.default.emergencyCancel.confirm')}
                    onConfirm={() => emergencyOff(selectedRowKeys, false)}
                >
                    <Button
                        className="ant-btn-green"
                        style={{ float: 'right' }}
                        disabled={!hasSelected || loading}
                        type="primary"
                    >
                        {geti18nText('devices.table.emergencyCancel')}
                    </Button>
                </Popconfirm>
                <Popconfirm
                    placement="topLeft"
                    title={geti18nText('app.default.emergencyOff.confirm')}
                    onConfirm={() => emergencyOff(selectedRowKeys, true)}
                >
                    <Button
                        danger
                        style={{ float: 'right', marginRight: '10px' }}
                        disabled={!hasSelected || loading}
                        type="primary"
                    >
                        {geti18nText('devices.table.emergencyOff')}
                    </Button>
                </Popconfirm>
            </React.Fragment>
        );
    };

    function startTimer() {
        setLoading(true);
        setTimer(Date.now() + 61 * 1000);
    }

    function stopTimer() {
        if (eventSourceRef.current) {
            eventSourceRef.current.close();
            eventSourceRef.current = undefined;
        }
        setTimer(undefined);
        setRefreshTable(refreshTable + 1);
    }

    return (
        <Spin
            spinning={timer != undefined}
            size="large"
            tip={
                <Countdown
                    title={geti18nText('devices.status.table.waiting.status')}
                    value={timer}
                    onFinish={stopTimer}
                    format="mm:ss"
                />
            }
        >
            <NyDataTable
                headerTitle={geti18nText('devices.table.header')}
                url={props.remapUrl == null ? CONSTANTS_REQ.DEVICE.LIST : props.remapUrl}
                addNewButtonText={geti18nText('devices.table.add')}
                buttonsClassName={'buttons-sticky'}
                showRecordModal={true}
                modalComponent={DeviceEdit}
                showRowSelection={canCreate()}
                selectOnSave={false}
                rowSelectionModal={rowSelectionModals}
                hideButtons={!canCreate()}
                setDefaultFilterValue={setDefaultFilterValue}
                scroll={{ y: 10000, x: 800 }}
                fetchWhenChange={refreshTable}
                columns={[
                    {
                        title: geti18nText('devices.table.column.id'),
                        dataIndex: 'id',
                        width: '5%',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('devices.table.column.name'),
                        dataIndex: 'name',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('devices.table.column.mac'),
                        dataIndex: 'macAddress',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('devices.table.column.substationId'),
                        dataIndex: ['substation', 'substationId'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    ...(NySession.hasAnyRole(['ROLE_SUPERADMIN'])
                        ? [
                              {
                                  title: geti18nText('devices.table.column.lastActivity'),
                                  dataIndex: 'lastActivity',
                                  sorter: (a: any, b: any) => {},
                                  ...getColumnDateOption(),
                                  render: (text: any, record: any) => {
                                      if (record.lastActivity) {
                                          return moment(record.lastActivity).format('DD.MM.YYYY HH:mm:ss');
                                      }
                                  },
                              },
                              {
                                  title: geti18nText('devices.table.column.communication'),
                                  render: (text: any, record: any) => {
                                      if (
                                          record.lastActivity &&
                                          moment().diff(moment(record.lastActivity), 'seconds') <= 86400
                                      ) {
                                          return <PoweroffOutlined style={{ color: 'green' }} />;
                                      } else {
                                          return <PoweroffOutlined style={{ color: 'red' }} />;
                                      }
                                  },
                              },
                          ]
                        : []),
                    {
                        title: geti18nText('devices.status.table.column.cb'),
                        render: (text: any, record: any) => {
                            if (record.deviceStatus && record.deviceStatus.cb) {
                                return <PoweroffOutlined style={{ color: 'green' }} />;
                            } else {
                                return <PoweroffOutlined style={{ color: 'red' }} />;
                            }
                        },
                    },
                    {
                        title: geti18nText('devices.status.table.column.r1'),
                        render: (text: any, record: any) => {
                            if (record.deviceStatus && record.deviceStatus.r1) {
                                return <PoweroffOutlined style={{ color: 'green' }} />;
                            } else {
                                return <PoweroffOutlined style={{ color: 'red' }} />;
                            }
                        },
                    },
                    {
                        title: geti18nText('devices.status.table.column.r2'),
                        render: (text: any, record: any) => {
                            if (record.deviceStatus && record.deviceStatus.r2) {
                                return <PoweroffOutlined style={{ color: 'green' }} />;
                            } else {
                                return <PoweroffOutlined style={{ color: 'red' }} />;
                            }
                        },
                    },
                    ...(NySession.hasAnyRole(['ROLE_SUPERADMIN'])
                        ? [
                              {
                                  title: geti18nText('devices.table.column.lastSync'),
                                  dataIndex: 'lastSync',
                                  sorter: (a: any, b: any) => {},
                                  ...getColumnDateOption(),
                                  render: (text: any, record: any) => {
                                      if (record.lastSync) {
                                          return moment(record.lastSync).format('DD.MM.YYYY HH:mm:ss');
                                      }
                                  },
                              },
                              {
                                  title: geti18nText('devices.table.column.zone'),
                                  dataIndex: 'timezone',
                                  sorter: (a: any, b: any) => {},
                                  ...getColumnSearch('string'),
                              },
                          ]
                        : []),
                    ...(!NySession?.getProjectInfo()?.parent?.id
                        ? [
                              {
                                  title: geti18nText('devices.table.column.project'),
                                  dataIndex: ['project', 'projectName'],
                                  sorter: (a: any, b: any) => {},
                                  ...getColumnSearch('string'),
                              },
                          ]
                        : []),
                    {
                        title: geti18nText('devices.table.column.active'),
                        dataIndex: 'active',
                        width: '10%',
                        render: (text: any, record: { active: any }) => {
                            if (record.active) {
                                return <CheckOutlined style={{ color: 'green' }} />;
                            } else {
                                return <CloseOutlined style={{ color: 'red' }} />;
                            }
                        },
                        ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
                        defaultFilteredValue: setDefaultFilterValue(),
                    },
                ]}
            />
        </Spin>
    );
};

export default DeviceIndex;
